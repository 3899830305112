
import { defineComponent, ref } from 'vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { useModals } from '@/compositions/modals'
import PageContent from '@/components/layout/pageContent/PageContent.vue'

export default defineComponent({
  components: {
    PageContent,
    TmAlert,
    InfoCards,
    TmButton,
    DetailsHero,
    InfoCard,
    InfoCardRow,
  },
  props: {
    isSystem: {
      type: Boolean,
    },
  },
  setup() {
    const { openModal } = useModals()
    const openAssigneeModal = () => {
      openModal('singleAssignee', {
        modalTitle: 'Assignee',
        label: 'Automatically assign to',
        btnText: 'Save',
      })
    }
    const openArchiveModal = () => {
      openModal('confirmation', {
        modalTitle: 'Archive',
        text: [
          'By archiving the',
          {
            text: ' Marketing ',
            style: 'semi-bold',
          },
          'board, you don\'t delete tasks from it, but users won\'t have access to them until you unarchive the board.',
        ],
        btnText: 'Archive',
      })
    }
    const breadcrumbsLink = ref<BreadcrumbsLink[]>([
      {
        label: 'Tasks',
        name: 'base.tasks.index',
      },
      {
        label: 'Boards',
        name: 'base.tasks.settings',
      },
      {
        label: 'Marketing',
      },
    ])
    const isArchived = ref(false)
    const handleArchive = () => {
      if (!isArchived.value) {
        openArchiveModal()
      }
      isArchived.value = !isArchived.value
    }
    const openDeleteModal = () => openModal('confirmation', {
      modalTitle: 'Delete board',
      text: [
        'By confirming this action, the ',
        {
          text: 'Marketing',
          style: 'semi-bold',
        },
        ' board and ',
        {
          text: 'all tasks',
          style: 'semi-bold',
        },
        ' on it will be deleted. This action cannot be undone. Are you sure you want to delete this board?',
      ],
      btnText: 'Delete board',
      btnColor: 'error',
    })

    return {
      isArchived,
      handleArchive,
      openDeleteModal,
      breadcrumbsLink,
      openArchiveModal,
      openAssigneeModal,
      openModal,
    }
  },
})
